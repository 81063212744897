import React from 'react';

const Frame = () => {
  return <div>
      <svg width="115" height="58" viewBox="0 0 115 58" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_1_4)">
<path d="M46.3 32.5C46.3 32.5 51.2 32.6 51.9 33.6C52.6 34.6 52.5 34.5 52.5 34.5C52.5 34.5 52.5 38.8 53.1 40.7C53.7 42.6 55.3 54.1 66.5 56.3C77.7 58.5 84.3 51.6 86.1 46.7C87.9 41.8 88.1 39.7 88.1 38.9C88.1 38 87.8 37 90.5 37C93.2 37 92.5 34.9 92.5 34.9C92.5 34.9 92.9 33.4 90.5 33.4C88.1 33.4 88.2 33.2 87.2 31.2C86.2 29.3 82.6 21.1 71.2 21.3C64.5 21.4 59.2 24.4 56.1 28.4C54.8 30.1 54.4 30.1 53.4 30.1C52.4 30.1 53.2 30.3 51.7 29.8C50.2 29.2 49 29 46.4 29.1H46C43.4 29.1 42.2 29.4 40.7 30C39.2 30.6 40.1 30.4 39 30.4C37.9 30.4 37.6 30.4 36.2 28.8C33 25 27.6 22.1 20.9 22.3C9.49998 22.4 6.19998 30.7 5.19998 32.7C4.19998 34.7 4.39998 34.9 1.99998 35C-0.400019 35.1 -1.89655e-05 36.5 -1.89655e-05 36.5C-1.89655e-05 36.5 -0.600019 38.5 2.09998 38.5C4.79998 38.5 4.49998 39.5 4.49998 40.4C4.49998 41.3 4.69998 43.4 6.69998 48.2C8.69998 53 15.5 59.6 26.6 57.1C37.7 54.6 39 43 39.5 41C40 39.1 39.8 34.8 39.8 34.8C39.8 34.8 39.6 34.8 40.3 33.8C41 32.8 45.9 32.6 45.9 32.6L46.3 32.5Z" fill="#787D9B"/>
<path d="M85.1999 36.7C85.0999 28.8 77.7999 23.8 70.6999 23.9C63.5999 24 57.1 28.2 56 36.5C54.8 44.7 61.1999 54.1 70.1999 54C79.1999 53.9 85.4 46.9 85.1999 36.7Z" fill="#DB93B0"/>
<path d="M56 41H56.3C56.2 40.5 56.2 40 56.2 39.5C56.2 38.8 56.7 39 56.7 39.2C56.7 39.5 57.2 42.3 57.8 43.9C57.8 43.9 58 45.1 57.4 44.2C57.2 43.7 57 43.3 56.9 42.8C56.7 42.1 56.3 42.3 56.3 42.3L56 41Z" fill="white"/>
<path d="M7.49997 37.5C7.59997 29.6 14.9 24.6 22 24.7C29.1 24.8 35.6 29 36.8 37.3C38 45.5 31.6 54.9 22.6 54.8C13.6 54.7 7.39997 47.7 7.49997 37.5Z" fill="#DB93B0"/>
<path d="M6.1 37.6C6.2 29 14.1 23.6 21.8 23.7C29.5 23.8 36.5 28.4 37.8 37.4C39.1 46.3 32.2 56.5 22.4 56.4C12.6 56.3 5.9 48.7 6.1 37.6Z" stroke="#FCFDFF" strokeWidth="0.5" strokeMiterlimit="10"/>
<path d="M86.4 36.6C86.3 28 78.4 22.6 70.7 22.7C63 22.8 56 27.4 54.7 36.4C53.4 45.4 60.3 55.5 70.1 55.4C79.8 55.4 86.6 47.8 86.4 36.6Z" stroke="#FCFDFF" strokeWidth="0.5" strokeMiterlimit="10"/>
<path d="M84.7 46.3C84.7 46.3 85.9 45.7 86.1 45.6C86.2 45.5 86.3 45.5 86.3 45.4C86.3 45.4 85.1 45.8 84.6 45.7L84.5 45.6C84.5 45.6 88 43.6 89.6 39.2C89.6 39.2 87.9 40.7 86.4 40.7C86.4 40.7 89.6 40 91.6 35.7C93.6 31.4 94.3 29.6 95.2 28.8C95.2 28.8 92.7 31.1 88.6 32.1C88.6 32.1 94.1 30.1 96.3 27C98.5 23.9 98.7 23.1 98.7 23.1C98.7 23.1 96 24.8 92.3 24.3C92.3 24.3 98.3 23.2 100.5 20.9C100.5 20.9 103.4 18.1 104 17.2C104.7 16.3 105.4 15.3 106 14.7C106 14.7 103.1 14.9 101.2 14.2C101.2 14.2 106.5 15.2 110.4 10.1C114.3 5 113.7 5.2 113.8 4.1C113.9 3 113.3 1.9 112.5 1.5L111.6 0.999999C111.6 0.999999 108.4 -0.600001 103 0.799999C103 0.799999 101.4 0.899999 98.8 3.6C96.2 6.3 96.5 6.7 96.3 8.6V10.4C96.3 10.4 94.9 8.6 95.9 5.4C95.9 5.4 92.6 8.3 91.3 9.8C90 11.3 89.5 13.5 89.3 15.5C89 17.5 89.1 18 89.1 18C89.1 18 88 16.2 88.5 12.6C88.5 12.6 84.7 17.4 83.9 19C83.1 20.6 83.1 22.9 83.4 24.6C83.7 26.3 83.7 27.5 83.7 27.5C83.7 27.5 82.8 26.6 82.5 24.1C82.5 24.1 81.5 28.1 81.2 29.3C80.9 30.5 80.8 31.8 82.1 33.9L83.3 36L83.9 36.6C83.9 36.6 82.5 36.2 80.8 33.7C80.8 33.7 80.9 33.9 81.1 35.4C81.3 36.9 81.8 39.3 83.7 41.3C83.7 41.3 82.5 40.8 82.4 40.7C82.3 40.6 82.8 43.1 83.6 44.1C83.2 43.9 82.8 43.7 82.4 43.4C82.4 43.4 83.1 44.4 83.4 44.7C83 44.8 82.6 44.8 82.2 44.7C82.2 44.7 82.9 45.2 83.4 45.4C83.4 45.4 83 45.8 82 46C82 46 83.2 46.2 83.5 46L83.7 45.9V46.3C83.6 46.6 83.4 46.9 83.1 47.1C83.1 47.1 83.5 47.1 83.6 46.9L84.7 46.3Z" fill="#787D9B" stroke="white" strokeWidth="0.5" strokeMiterlimit="10"/>
<path d="M90.3 22.5C90.3 22.5 87.4 27.3 86.5 30.1C85.6 32.9 85.2999 33.4 84.5999 36.5C83.8999 39.6 83.6999 42.4 83.5999 43.4C83.5999 44.4 83.1 51 83.5 52.1C83.9 53.2 83.6999 52.7 83.6999 52.7C83.6999 52.7 83.9 53.7 84.5 53.8C84.6 53.5 84.7 53.2 84.8 52.9L84.9 52.6C84.9 52.6 84.5999 49.4 84.5999 47.8C84.5999 46.2 84.1999 43.9 85.6999 36.2C87.1999 28.4 92.1999 20.9 92.1999 20.9C92.1999 20.9 93.7999 18.3 96.5999 15.2C99.4 12.1 102.3 8.60001 103.2 8.10001C103.2 8.10001 98 12.5 95 16.3C95 16.2 92 19.9 90.3 22.5Z" fill="white" stroke="#DB93B0" strokeMiterlimit="10"/>
<path d="M36.7 41.8H36.4C36.5 41.3 36.5 40.8 36.5 40.3C36.5 39.6 36 39.8 36 40C36 40.3 35.5 43.1 34.9 44.7C34.9 44.7 34.7 45.9 35.3 45C35.5 44.5 35.7 44.1 35.8 43.6C36 42.9 36.4 43.1 36.4 43.1L36.7 41.8Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_1_4">
<rect width="114.1" height="57.5" fill="white"/>
</clipPath>
</defs>
</svg>

  </div>;
};

export default Frame;
