import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './components/Papers/List.css'
import './Loader.css'
import './PageHeader.css'
import './Form.css'
import './Blogs.css'
import './ContentContainer.css'
import './Visibility.css'
import './DocView.css'
import './Button.css'
import './BoxLayout.css'
import './InputGroup.css'
import './Inputs.css'
import './ShareButton.css'
import './NotFoundPage.css'
import './Rules.css'
import './Advice.css'
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import configureStore from './store/configureStore';
import TeamBuildingModal from './components/TeamBuildingModal';
import AppRouter from './routers/AppRouter';
import LoadingPage from './components/Pages/LoadingPage';
import { firebase } from './firebase/firebase';
import { startSetCat1papers } from './actions/cat1papers';
import { startSetCat2papers } from './actions/cat2papers';
import { startSetFatpapers } from './actions/fatpapers';
import { startSetMaterials } from './actions/materials';
import { startSetFaqs } from './actions/faqs';
import { startSetPlacements } from './actions/placements';


const store = configureStore();
const notShowModal = ["/blogs","/onlinevsoffline","/placement-guide","/room-counselling","/hostel-vacating-procedure","/npm-package","/placement-experience"]

const jsx = (
  <Provider store={store}>
    <div>
      {
        !notShowModal.includes(window.location.pathname) ? <TeamBuildingModal /> : null 
      }
      <AppRouter />
    </div>
  </Provider>
);

let hasRendered = false;
const renderApp = () => {
  if (!hasRendered) {
    store.dispatch(startSetCat2papers());
    store.dispatch(startSetFatpapers());
    store.dispatch(startSetMaterials());
    store.dispatch(startSetFaqs());
    store.dispatch(startSetPlacements());
    store.dispatch(startSetCat1papers()).then(() => {
      try{
        ReactDOM.createRoot(document.getElementById('root'))
        root.render(jsx);
      }      
      catch(e){}
    });
    hasRendered = true;
  }
};

// ReactDOM.render(<LoadingPage />, document.getElementById('root'));

firebase.auth().onAuthStateChanged((user) => {
  setTimeout(() => {
    renderApp();
  }, 2300)
});


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <LoadingPage />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
