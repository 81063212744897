import React, { useState, useEffect } from "react";
import { BsArrowUpCircle, BsFillArrowUpCircleFill } from "react-icons/bs";
import { Button } from "./ScrollButtonStyles";
import _ from "lodash"; // Import lodash library

const ScrollButton = () => {
  const [isTouch, setIsTouch] = useState(false);
  const [visible, setVisible] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrolled = document.documentElement.scrollTop;
      setVisible(scrolled > 300);
    };

    const handleResize = () => {
      setIsTouch(window.innerWidth < 1100);
    };

    const throttledScroll = _.throttle(handleScroll, 200); // Throttle scroll event
    const debouncedResize = _.debounce(handleResize, 200); // Debounce resize event

    window.addEventListener("scroll", throttledScroll);
    window.addEventListener("resize", debouncedResize);

    handleResize(); // Initial check
    handleScroll(); // Initial check

    return () => {
      window.removeEventListener("scroll", throttledScroll);
      window.removeEventListener("resize", debouncedResize);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <Button
      className={isTouch ? "" : "hoverable"}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{ display: visible ? "block" : "none" }}
    >
      {isTouch || isHovered ? (
        <BsFillArrowUpCircleFill onClick={scrollToTop} />
      ) : (
        <BsArrowUpCircle />
      )}
    </Button>
  );
};

export default ScrollButton;
