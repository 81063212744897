import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/storage';
import 'firebase/auth';

const config = {
  apiKey: 'AIzaSyCBfxTq-enEyDNaT1mDDx_dffeaxPPtWVA',
  authDomain: 'vithelper-32e0b.firebaseapp.com',
  databaseURL: 'https://vithelper-32e0b.firebaseio.com',
  projectId: 'vithelper-32e0b',
  storageBucket: 'vithelper-32e0b.appspot.com',
  messagingSenderId: '571499579120'
};

// Initialize Firebase
firebase.initializeApp(config);
const database = firebase.database();
const storage = firebase.storage();

const googleAuthProvider = new firebase.auth.GoogleAuthProvider();

export { storage, firebase, googleAuthProvider, database as default };